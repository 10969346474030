<template>
  <div class="bg global-blue">
    <v-container class="hidden-md-and-down">
      <!-- <eulogos :w="120"></eulogos> -->

      <v-row>
        <v-col>
          <h1 class="my-8 global-title-sm hidden-md-and-up">{{ translations.title[locale] }}</h1>
          <h1 class="my-8 global-title hidden-sm-and-down">{{ translations.title[locale] }}</h1>
        </v-col>
      </v-row>
      <v-card
        class="dark-bg pa-12"
        flat
        dark
        outlined
      >
        <v-row>

          <v-col cols="5">
            <v-card
              class="dark-bg pa-6"
              flat
              dark
            >
              <v-card-title>{{ info_title[locale] }}</v-card-title>

              <p
                class="normal-text mx-4"
                v-for="(t,i) in text[locale]"
                cols="6"
                :key="i"
              >{{t}}</p>

              <div class="mt-12">

                <a
                  :href="p[locale].link"
                  class="partners"
                  v-for="(p,pi) in partners"
                  :key="`p${pi}`"
                >
                  <p class="mx-4">{{ p[locale].name }}</p>
                </a>
              </div>

            </v-card>
          </v-col>
          <v-col cols="7">
            <v-card
              class="dark-bg"
              flat
              dark
              @click.stop="dialog = true"
            >
              <v-img
                v-if="locale == 'fi'"
                class="align-end"
                src="@/assets/Mikae_on_klusteri_tummalle_taustalle_FI.svg"
                contain
                height="1000px"
              >
                <p class="normal-text mx-4">
                  {{ imgText.fi }}
                </p>
              </v-img>
              <v-img
                v-if="locale == 'en'"
                class="align-end"
                src="@/assets/Mikae_on_klusteri_tummalle_taustalle_EN.svg"
                contain
                height="1000px"
              >
                <p class="normal-text mx-4">
                  {{ imgText.en }}
                </p>
              </v-img>
              <!-- <v-img
              class="align-end"
              src="@/assets/cluster_info_dark.svg"
              contain
              height="800"
            >
              <p class="">
                Mikä on klusteri.
              </p>
            </v-img> -->

            </v-card>
          </v-col>
        </v-row>
      </v-card>

    </v-container>
    <v-container class="hidden-lg-and-up">

      <v-row>
        <v-col>
          <h1 class="my-8 global-title-sm hidden-md-and-up">{{ translations.title[locale] }}</h1>
          <h1 class="my-8 global-title hidden-sm-and-down">{{ translations.title[locale] }}</h1>
        </v-col>
      </v-row>
      <v-card
        class="dark-bg pa-4 bg-btm"
        flat
        dark
        outlined
      >
        <v-row>
          <v-col cols="12">
            <v-card
              class="dark-bg pa-4"
              flat
              dark
            >
              <v-card-title>{{ info_title[locale] }}</v-card-title>

              <p
                v-for="(t,i) in text[locale]"
                cols="6"
                :key="i"
                class="normal-text mx-4"
              >{{t}}</p>

              <div class="mt-12">

                <a
                  :href="p[locale].link"
                  class="partners"
                  v-for="(p,pi) in partners"
                  :key="`p${pi}`"
                >
                  <p class="mx-4">{{ p[locale].name }}</p>
                </a>
              </div>

            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card
              class="dark-bg "
              flat
              dark
              max-height="1000px"
              @click.stop="dialog = true"
            >
              <v-img
                v-if="locale == 'fi'"
                class="align-end"
                src="@/assets/Mikae_on_klusteri_tummalle_taustalle_FI.svg"
                contain
                height="800"
              >
                <v-card-text class="mb-n4 mx-n4">
                  {{ imgText.fi }}
                </v-card-text>

              </v-img>
              <v-img
                v-if="locale == 'en'"
                class="align-end"
                src="@/assets/Mikae_on_klusteri_tummalle_taustalle_EN.svg"
                contain
                height="800"
              >
                <v-card-text class="mb-n4 mx-n4">
                  {{ imgText.en }}
                </v-card-text>
              </v-img>
              <!-- <v-img
              class="align-end"
              src="@/assets/cluster_info_dark.svg"
              contain
              height="800"
            >
              <p class="">
                Mikä on klusteri.
              </p>
            </v-img> -->

            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog">
          <v-card
            class="dark-bg"
            dark
            @click.stop="dialog = false"
            min-height="1000px"
            min-width="1000px"
          >
            <v-img
              v-if="locale == 'fi'"
              class="align-end"
              src="@/assets/Mikae_on_klusteri_tummalle_taustalle_FI.svg"
              min-height="1200"
              contain
            >
              <v-card-text>
                {{ imgText.fi }}
              </v-card-text>
            </v-img>
            <v-img
              v-if="locale == 'en'"
              class="align-end"
              src="@/assets/Mikae_on_klusteri_tummalle_taustalle_EN.svg"
              min-height="1200"
              contain
            >
              <v-card-text>
                {{ imgText.en }}
              </v-card-text>
            </v-img>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </div>
</template>

<style scoped>
.bg {
  height: 100%;
  min-height: 1700px;
}
/* h1 {
  color: #f7941e;
  font-size: 26px;
  font-weight: 500;
  text-align: start;
} */
h2 {
  font-size: 20px;
}
.title-o {
  font-size: 32px;
  color: #f7941e;
  font-weight: 500;
  margin-top: -20px;
}
.title-w {
  font-size: 32px;
  color: white;
  font-weight: 500;
  margin-top: -20px;
}
.title-env {
  font-size: 20px;
}
.emp {
  font-size: 16px;
}
.inp {
  color: white;
}
.dark-bg {
  background: var(--v-primary-base) !important;
}
.bg-btm {
  margin-bottom: 200px;
}
.normal-text {
  font-size: 18px;
  color: white;
}
.partners {
  text-decoration: none;
  color: white;
  font-weight: 600;
}
</style>

<script>
import { PORTAL_CLUSTER } from "../../constants/translations";
// import eulogos from "../../components/EU_Logos_In_Pages";

export default {
  name: "Cluster",
  components: {
    //eulogos
  },
  data() {
    return {
      dialog: false,
      translations: PORTAL_CLUSTER,
      info_title: {
        fi: "KLUSTERI-INFO",
        en: "ABOUT CLUSTER"
      },
      text: {
        fi: [
          "Lapin älykkään erikoistumisen strategiaa on toteutettu vuodesta 2013 alkaen. Älykäs erikoistuminen mahdollistaa entistä tehokkaammat yhteistyön mahdollisuudet ja kattavammat TKI-palvelut. Älykkään erikoistumisen tuloksena on syntynyt kuusi Arktista klusteria (Arctic Smartness Clusters) Näitä ovat Kehittämisympäristöt-, Muotoilu-, Turvallisuus-, Matkailu-, Älykäs maaseutuverkosto- ja Teollisuus ja Kiertotalousklusterit. Klustereiden tavoitteena on tukea niitä toimialoja, jotka pohjautuvat lappilaisille vahvuuksille ja jolla on suurin kasvupotentiaali.",
          "Älykäs erikoistuminen on synnyttänyt uusia yhteistyörakenteita ja toimintamalleja alueen kehittämiseksi. Tavoitteena on auttaa klustereissa mukana olevia PK-yrityksiä erikoistumaan ja kasvamaan sekä siirtymään kansainvälisille markkinoille.",
          "Arktisten klustereiden kehittämistyötä johtavat Lapin liitto, Lapin ammattikorkeakoulu, Lapin yliopisto, Digipolis, ProAgria Lappi, Luonnonvarakeskus sekä Geologian tutkimuskeskus. Klusteriyhteistyössä on mukana yli 150 lappilaista yritystä ja noin 20 muuta alueellista toimijaa."
        ],
        en: [
          "Cluster-based collaboration as part of Lapland's smart specialisation strategy has been implemented since 2013. Smart specialisation collaboration empowers more critical mass and comprehensiveness through efficient collaboration and modular multidisciplinary RDI services. Cluster collaboration as part of Smart Specialisation in Lapland has led to the creation of six Arctic clusters, which are Development Environments, Design, Safety, Tourism, Intelligent Rural Network and Industry and Circular Economy Clusters. Clusters support the industries in Lapland with the most strength and the greatest potential for growth.",
          "In addition to new collaborative structures Smart specialisation has created new collaboration and agile models of operating in the Lapland region. The aim is to help SMEs involved with the above mentioned Arctic clusters to specialise, be more resilient, grow and successfully access international markets.",
          "Arctic clusters development work mandated by the Regional Council of Lapland and EDE Cluster is led the Lapland University of Applied Sciences in collaboration with the University of Lapland, Digipolis, ProAgria Lappi, Center of Natural Resources and Geological Survey of Finland. The ADE cluster co-operation has involved more than 150 companies and about 20 other regional actors in Lapland."
        ]
      },
      link:
        "https://www.luc.fi/fi/Tutkimus-ja-kehittaminen/Arctic-Smartness-Excellence",
      imgText: {
        fi: "Mikä on klusteri. Kuva: Lapin materiaalipankki.",
        en: "How the cluster works. Image: Lapland Material Bank."
      },
      partners: [
        {
          fi: {
            name: "Lapin liitto",
            link: "http://www.lappi.fi/lapinliitto/fi"
          },
          en: {
            name: "Regional Council of Lapland",
            link: "http://www.lappi.fi/lapinliitto/en"
          }
        },
        {
          fi: {
            name: "Lapin ammattikorkeakoulu",
            link: "https://www.lapinamk.fi/"
          },
          en: {
            name: "Lapland University of Applied Sciences",
            link: "https://www.lapinamk.fi/en"
          }
        },
        {
          fi: {
            name: "Lapin yliopisto",
            link: "https://www.ulapland.fi/"
          },
          en: {
            name: "University of Lapland",
            link: "https://www.ulapland.fi/EN"
          }
        },
        {
          fi: {
            name: "Digipolis",
            link: "https://www.digipolis.fi/"
          },
          en: {
            name: "Digipolis",
            link: "https://www.digipolis.fi/en/"
          }
        },
        {
          fi: {
            name: "ProAgria Lappi",
            link: "https://lappi.proagria.fi/"
          },
          en: {
            name: "ProAgria",
            link: "https://proagria.fi/en"
          }
        },
        {
          fi: {
            name: "Luonnonvarakeskus",
            link: "https://www.luke.fi/"
          },
          en: {
            name: "Natural Resources Institute Finland",
            link: "https://www.luke.fi/en/"
          }
        },
        {
          fi: {
            name: "Geologian tutkimuskeskus",
            link: "https://www.gtk.fi/"
          },
          en: {
            name: "Geological Survey of Finland",
            link: "https://www.gtk.fi/en/"
          }
        }
      ]
    };
  },
  methods: {},
  computed: {
    locale() {
      return this.$store.state.locale;
    }
  }
};
</script>
